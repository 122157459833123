import axios from 'axios'

class SettingApi {
    async getSetting() {
        axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
        return axios
            .get('settings')
            .then(Response => Response)
            .catch(() => ({ error: true, results: [] }))
    }


    async editSetting(dollar_to_iq) {
        axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
        return axios
            .put('settings/currency', {
                dollar_to_iq: +dollar_to_iq
            })
            .then(Response => Response)
            .catch(() => ({ error: true, results: [] }))
    }

}

export default new SettingApi()