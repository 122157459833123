import Vue from "vue";
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import VueFeather from "vue-feather";

import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib' //Globally import VTextField

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
    locale: 'en-US',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false
})

Vue.use(VueAxios, axios)

axios.defaults.baseURL = 'https://api.dzn-printer.art/api/web/'

// axios.defaults.baseURL = 'http://192.168.9.196:4400/api/web/'

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            store.dispatch('submitLogout')
            router.push('/')

            // location.reload()
        }

        if (error.response.status === 402) {
            router.push('/paymentRequired')
        }

        return Promise.reject(error)
    },
)


Vue.use(VueFeather);

Vue.use(VueSkycons, {
    color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);

new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");