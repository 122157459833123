import Vue from "vue";
import Router from "vue-router";
// import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
    // mode: "history",
    // base: process.env.BASE_URL,
    // // This is for the scroll top when click on any router link
    // scrollBehavior: (to, from, savedPosition) => {
    //     let scrollTo = 0;

    //     if (to.hash) {
    //         scrollTo = to.hash;
    //     } else if (savedPosition) {
    //         scrollTo = savedPosition.y;
    //     }

    //     return goTo(scrollTo);
    // },

    // This is for the scroll top when click on any router link
    routes: [{
            path: "/",
            redirect: "authentication/boxedlogin",
            // component: () =>
            //     import ("@/layouts/blank-layout/Blanklayout"),
            // children: [{
            //     name: "BoxedLogin",
            //     path: "boxedlogin",
            //     component: () =>
            //         import ("@/views/authentication/BoxedLogin"),
            // }, ]
        },

        {
            path: "/dashboards",
            component: () =>
                import ("@/layouts/full-layout/Layout"),
            children: [{
                    name: "home",
                    path: "home",
                    component: () =>
                        import ("@/views/home/home"),
                },

                // customer
                {
                    name: "customers",
                    path: "customers",
                    component: () =>
                        import ("@/views/customers/customers"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },

                // employee
                {
                    name: "employee",
                    path: "employee/show",
                    component: () =>
                        import ("@/views/employee/employee"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },

                // loan
                {
                    name: "loan",
                    path: "employee/show/loan/id/:id/name/:name",
                    component: () =>
                        import ("@/views/employee/loan/loan"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                // loan payment
                {
                    name: "loanPayment",
                    path: "employee/show/loan/payment/id/:id/name/:name",
                    component: () =>

                        import ("@/views/employee/loan/loanPayment"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        }
                    },
                },


                // salary
                {
                    name: "salary",
                    path: "employee/salary",
                    component: () =>
                        import ("@/views/employee/salary/salary"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "salaryAdd",
                    path: "employee/salary/add",
                    component: () =>
                        import ("@/views/employee/salary/salaryAdd"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "salaryEdit",
                    path: "employee/salary/edit",
                    component: () =>
                        import ("@/views/employee/salary/salaryEdit"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },

                // service
                {
                    name: "service",
                    path: "employee/service",
                    component: () =>
                        import ("@/views/employee/service"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },

                // expenses
                {
                    name: "expenses",
                    path: "expenses/show",
                    component: () =>
                        import ("@/views/expenses/expenses"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "expensesService",
                    path: "expenses/service",
                    component: () =>
                        import ("@/views/expenses/expensesService"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },

                // wallet
                {
                    name: "wallet",
                    path: "wallet",
                    component: () =>
                        import ("@/views/wallet/wallet"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },

                },

                // setting
                {
                    name: "setting",
                    path: "setting",
                    component: () =>
                        import ("@/views/setting/setting"),
                },

                // sales invoice
                {
                    name: "salesInvoice",
                    path: "salesInvoice",
                    component: () =>
                        import ("@/views/salesInvoice/salesInvoice"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "salesInvoiceAdd",
                    path: "salesInvoice/add",
                    component: () =>
                        import ("@/views/salesInvoice/salesInvoiceAdd"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "salesInvoiceEdit",
                    path: "salesInvoice/edit",
                    component: () =>
                        import ("@/views/salesInvoice/salesInvoiceEdit"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "salesInvoiceShow",
                    path: "salesInvoice/show/id/:id/name/:name",
                    component: () =>
                        import ("@/views/salesInvoice/salesInvoiceShow"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "salesInvoiceShowPayment",
                    path: "salesInvoice/show/payment/id/:id/name/:name",
                    component: () =>
                        import ("@/views/salesInvoice/salesInvoiceShowPayment"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "salesInvoiceShowDiscount",
                    path: "salesInvoice/show/discount/id/:id/name/:name",
                    component: () =>
                        import ("@/views/salesInvoice/salesInvoiceShowDiscount"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },

                // purchase invoice
                {
                    name: "purchaseInvoice",
                    path: "purchaseInvoice",
                    component: () =>
                        import ("@/views/purchaseInvoice/purchaseInvoice"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "purchaseInvoiceAdd",
                    path: "purchaseInvoice/add",
                    component: () =>
                        import ("@/views/purchaseInvoice/purchaseInvoiceAdd"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "purchaseInvoiceEdit",
                    path: "purchaseInvoice/edit",
                    component: () =>
                        import ("@/views/purchaseInvoice/purchaseInvoiceEdit"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "purchaseInvoiceShowPayment",
                    path: "purchaseInvoice/show/payment/id/:id/name/:name",
                    component: () =>
                        import ("@/views/purchaseInvoice/purchaseInvoiceShowPayment"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },

                // saleByMeasure
                {
                    name: "saleByMeasure",
                    path: "saleByMeasure/show",
                    component: () =>
                        import ("@/views/saleByMeasure/show"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "saleByMeasureAdd",
                    path: "saleByMeasure/add",
                    component: () =>
                        import ("@/views/saleByMeasure/add"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "saleByMeasureEdit",
                    path: "saleByMeasure/show/edit",
                    component: () =>
                        import ("@/views/saleByMeasure/edit"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "saleByMeasureShow",
                    path: "saleByMeasure/show/bill/id/:id/name/:name",
                    component: () =>
                        import ("@/views/saleByMeasure/bills/measureItems"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "saleByMeasurePayment",
                    path: "saleByMeasure/show/payment/id/:id/name/:name",
                    component: () =>
                        import ("@/views/saleByMeasure/bills/payments"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },
                {
                    name: "saleByMeasureDiscounts",
                    path: "saleByMeasure/show/discount/id/:id/name/:name",
                    component: () =>
                        import ("@/views/saleByMeasure/bills/discounts"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },

                // material
                {
                    name: "material",
                    path: "saleByMeasure/material",
                    component: () =>
                        import ("@/views/saleByMeasure/material"),
                    beforeEnter(to, from, next) {
                        if (
                            localStorage.getItem('accessToken')
                        ) {
                            next()
                        } else {
                            next('/')
                        }
                    },
                },

            ]
        },

        {
            path: "/authentication",
            component: () =>
                import ("@/layouts/blank-layout/Blanklayout"),
            children: [{
                    name: "BoxedLogin",
                    path: "boxedlogin",
                    component: () =>
                        import ("@/views/authentication/BoxedLogin"),
                },

                // salary print
                {
                    name: "salaryPrint",
                    path: "salary/print/id/:id",
                    component: () =>
                        import ("@/views/employee/salary/print/salaryPrint"),
                },
                {
                    name: "salaryMainPrint",
                    path: "salary/print/main",
                    component: () =>
                        import ("@/views/employee/salary/print/salaryMainPrint"),
                },
                // purchaseInvoice print
                {
                    name: "purchasePaymentPrint",
                    path: "purchasePayment/print/id/:id/name/:name",
                    component: () =>
                        import ("@/views/purchaseInvoice/print/printPayment"),
                },

                // salesInvoice print
                {
                    name: "salesPaymentPrint",
                    path: "salesPayment/print/id/:id/name/:name",
                    component: () =>
                        import ("@/views/salesInvoice/print/printPayment"),
                },

                // saleInvoice print
                {
                    name: "salesInvoicePrint",
                    path: "salesInvoice/print",
                    component: () =>
                        import ("@/views/salesInvoice/print/salesInvoicePrint"),
                },
                {
                    name: "salesMainPrint",
                    path: "salesInvoice/main/print",
                    component: () =>
                        import ("@/views/salesInvoice/print/mainSalePrint"),
                },


                // purchaseInvoice print
                {
                    name: "purchaseInvoicePrint",
                    path: "purchaseInvoice/print",
                    component: () =>
                        import ("@/views/purchaseInvoice/print/purchaseInvoicePrint"),
                },

                {
                    name: "purchaseMainPrint",
                    path: "purchaseInvoice/main/print",
                    component: () =>
                        import ("@/views/purchaseInvoice/print/mainPurchasePrint"),
                },

                // saleByMeasure print
                {
                    name: "saleByMeasurePaymentPrint",
                    path: "saleByMeasure/show/payment/print/id/:id/name/:name",
                    component: () =>
                        import ("@/views/saleByMeasure/bills/print/payment"),
                },
                {
                    name: "saleByMeasureInvoicePrint",
                    path: "saleByMeasure/show/print/id/:id/name/:name",
                    component: () =>
                        import ("@/views/saleByMeasure/print/salePrint"),
                },
                {
                    name: "saleByMeasureMainPrint",
                    path: "saleByMeasure/print/main",
                    component: () =>
                        import ("@/views/saleByMeasure/print/mainSalePrint"),
                },

                // expense print
                {
                    name: "expensesMainPrint",
                    path: "expense/show/payment/print",
                    component: () =>
                        import ("@/views/expenses/print"),
                },

            ],
        },
        {
            path: "*",
            component: () =>
                import ("@/views/authentication/Error"),
        },


        // {
        //     path: "*",
        //     component: () =>
        //         import ("@/views/Error"),
        // },
    ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start(800);
    }
    next();
});

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done();
});

export default router;